@import "../../../../styles/variables";
@import "../../../../styles/servelegal-font-variables";
@import "../../../../styles/guide";

.iconDownload {
  @extend %icon-after;
  margin-right: 9px;
  &::after {
    content: $icon-download;
    font-size: 10px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.buttonDownload {
  background-color: $AuxiliarColor !important;
  color: $WhiteColor !important;
  margin: 40px auto 0px auto !important;
  padding: 14px 24px !important;
}

.downloadContainer {
  margin-bottom: 48px;
}
