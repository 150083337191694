@import '../../../../styles/guide';
@import '../../../../styles/variables';

.formFieldCheckboxWrapperParent {
  div {
    align-items: center;
    display: flex !important;
    margin-left: 20px;
  }
}

.content {
  align-items: center;
  color: #acacac;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
