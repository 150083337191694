.exportMenu {
  margin: 0 12px !important;
}

.menuItem {
  display: flex;
  justify-content: space-between;
}

.menuItemIcon {
  padding-left: 15px;
}

.exportLoaderContainer {
  top: 20% !important;
}
