@import "../../../../styles/variables";

.question {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
  user-select: none;

  @media (min-width: $mobile-width) {
    width: 50%;
  }
}

.error {
  color: $PrimaryColor;
  font-weight: $Bold;
  padding: 5px 0;
}