@import '../../../styles/guide';
@import '../../../styles/servelegal-font-variables';

.legendAudits {
  font-weight: bold;
  padding: 8px 16px !important;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.infoTitle {
  background-color: $WhiteColor;
  font-weight: bold;
  width: 100%;
}

.locationIcon {
  @extend %icon-before;
  font-size: 12px;

  &::before {
    content: $icon-visits;
  }
}

.auditTableMap {
  display: flex;
  flex-direction: column;
}

.auditTableRow {
  display: flex;
  flex-direction: row;
  padding: 0.3em 0.5em;
}

.auditTableLegend {
  min-width: 150px;
}