@import '../../../../../styles/variables';
@import '../../../../../styles/guide';
@import '../../../../../styles/servelegal-font-variables';

.headerContainer {
  background-color: $SecondaryColor !important;
}

.header {
  color: $WhiteColor !important;
}

%action-icon {
  @extend %icon;
  cursor: pointer;
}

.add,
.remove {
  @extend %action-icon;
}

.add {
  &::before {
    content: $icon-add_square;
  }
}

.remove {
  &::before {
    content: $icon-trash;
  }
}
