@import "../../../../../styles/variables";

.required {
  color: $PrimaryColor;
}

.help {
  color: $HelpColor;
  margin-bottom: 8px;
}

.auditorInfo {
  margin-bottom: 8px;
}
