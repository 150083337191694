@import "../../../../styles/guide";
@import '../../../../styles/servelegal-font-variables';

.infoContainer {
  width: 200px;
}

.infoTitleAuditor{
  background-color: $WhiteColor;
  font-size: 16px;
  font-weight: bold;
  padding: 8px;
  text-align: center;
  width: 100%;
}

.infoTitle{
  align-items: baseline;
  background-color: $WhiteColor;
  display: flex;
  font-weight: bold;
  justify-content: center;
  padding: 4px 0px 12px 0px;
  text-align: center;
  width: 100%;
}

.infoContent {
  background-color: $FogColor;
  border-radius: 8px;
  margin-bottom: 6px;
  margin-left: 6px;
  padding: 15px 0px !important;
}

.row {
  padding: 0px 16px;
}

.rowDouble {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 16px;
}

.hidden {
  display: none;
}

.containerButton {
  display: flex;
  justify-content: center;
}

.button {
  width: 90%;
  a{
    color: $WhiteColor
  }
}

.auditIcon {
  @extend %icon-before;
  color: $PrimaryColor;
  font-size: 12px;

  &::before {
    content: $icon-visits_current;
  }
}

.deadline {
  color: $PrimaryColor;
}

.locationIcon {
  @extend %icon-before;
  font-size: 12px;

  &::before {
    content: $icon-visits;
  }
}

.deadlineIcon {
  @extend %icon-before;
  color: $PrimaryColor;
  font-size: 12px;

  &::before {
    content: $icon-deadline;
  }
}

.estimateIcon {
  @extend %icon-before;
  font-size: 12px;

  &::before {
    content: $icon-estimate;
  }
}

.mileageIcon {
  @extend %icon-before;
  font-size: 12px;

  &::before {
    content: $icon-mileage;
  }
}

.iconList {
  @extend %icon-before;
  font-size: 12px;

  &::before {
    content: $icon-radio;
  }
}