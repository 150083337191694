@import "../../../styles/servelegal-font-variables";
@import "../../../styles/variables";

.root {
  align-items: center;
  background-color: #5F8298;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.03);
  color: white;
  display: flex;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  justify-content: space-between;
  margin: 5px 0;
  padding: 10px 15px;
  width: 600px;
}

.success {
  color: $SuccessColor;
}

.error {
  color: $ErrorColor;
}

.message {
  height: auto;
  margin: 0px 5px;
  width: 480px;
}

.close {
  box-shadow: 0em 0em 0em 0.1em rgba(255, 255, 255, 0.9) inset !important;
  cursor: pointer;
}

.rootMobile {
  width: 300px;
}
