@import '../../../../styles/variables';

.listItem {
  margin: 1px 0;
}

.required {
  color: $PrimaryColor;
}

.question {
  font-weight: bold;
}

.hidden {
  display: none;
}

.missing {
  color: $PrimaryColor;
}

.image {
  margin-bottom: 5px;
  margin-right: 5px;
}