@import '../../../styles/variables';
@import '../../../styles/guide';
@import '../../../styles/servelegal-font-variables';

.tabMobile {
  align-items: center;
  background-color: $PrimaryColor;
  border-radius: 8px 8px 0px 0px;
  color: $WhiteColor;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 56px;
  justify-content: center;
  padding: 0px 4px;
  position: relative;
}

.tabIcon {
  @extend %icon-before;
  font-size: 1.5rem;
  padding-left: 6px;
  z-index: 1;
  &::before {
    content: $icon-mobile_phone;
  }
}

.tabLabel {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  z-index: 1;
}