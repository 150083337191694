@import '../../../../../styles/guide';

.link {
  @extend %table-link;
}

.bold {
  font-weight: bold;
}

.primary {
  color: $PrimaryColor;
}

.secondary {
  color: $SecondaryColor;
}

.warning {
  color: #FFC107;
}
