@import '../../../styles/guide';
@import '../../../styles/servelegal-font-variables';
@import '../../../styles/_variables';

.row {
  background-color:rgba(100,100,100, 0);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px;
  width: 100%;

  i {
    cursor: pointer;
  }
}

.iconsContainer {
  display: flex;
}

.mainList {
  border: 1px solid $AuxiliarColor;
  border-radius: 5px;
}

.mainListItem {
  background: $WhiteColor;
  border-bottom: 1px solid $AuxiliarColor;
  padding: 12px 22px !important;
  -moz-transition: background-color .4s ease-out;
  -webkit-transition: background-color .4s ease-out;
  -o-transition: background-color .4s ease-out;
  transition: background-color .4s ease-out;
  &:hover {
    background-color:rgba(100,100,100, 0.05)
  }
  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:last-child {
    border-bottom: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px
  }
}

.subRowItem {
  width: 100vw !important;
  &::after {
    background: $AuxiliarColor;
    content: '';
    display: block;
    height: 1px;
    margin-top: 8px;
    transition: width .3s;
    width: 0;
  }
  &:hover::after {
    width: 100%;
  }
}

.folderLine {
  cursor: pointer
}

.addIcon {
  color: $PrimaryColor
}
