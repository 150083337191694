// Colors
$PrimaryColor: #EE485F;
$SecondaryColor: #5F8298;
$AuxiliarColor: #92ADBE;
$GrayColor: #DFE6ED;
$FogColor: #EBF0F5;
$WhiteColor: #FFFFFF;
$HelpColor: #919A9F;
$SuccessColor: #8ABF50;
$ErrorColor: #c84858;

// Size
$navbar-width: 90px;
$navbar-min-height: 1070px;

// Media
$mobile-width: 480px;

// Weight
$Bold: 600;