@import "../../../../styles/variables";

.newsFeed {
  color: $WhiteColor;
}

.newsFeedMobile {
  background-color: $FogColor;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 20px 16px 20px 16px;
}

.newsItem {
  color: $WhiteColor;
  margin-top: 16px;
  div:first-child {
    border-radius: 8px !important;
  }
  @media (max-width: $mobile-width) {
    color: $SecondaryColor;
  }
}
