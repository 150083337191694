@import '../../../../../../styles/variables';

.root {
  display: flex;
  flex-direction: column;
  height: 95%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.questions {
  background-color: $WhiteColor;
  height: 85%;
  width: 100%;

  > div {
    padding: 5px;
  }
}

.noQuestions {
  align-items: center;
  color: $HelpColor;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.drawing {
  background-image: url('../../../../../../assets/images/empty-form-builder.svg');
  background-position: center;
  background-repeat: no-repeat;
  height: 224px;
  width: 158px;
}

.placeholder {
  padding-top: 30px;
}
