
@import '../../../../styles/variables';

.mapContainer {
  height: 100%;
  min-height: 100%;
}

.map {
  border-radius: 0px 0px 8px 8px;
  height: 100%;
  width: 100%;
}