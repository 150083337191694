@import '../../../../../styles/guide';
@import '../../../../../styles/servelegal-font-variables';

.navigation {
  display: flex;
}

.back {
  @extend %icon-before;

  &::before {
    content: $icon-arrowLeft_mid;
    padding-right: 5px;
  }
}

// TODO: set correct icon
.next {
  // @extend %icon-after;
  margin-left: auto !important;

  // &::after {
  //   content: $icon-arrowLeft_mid;
  //   padding-left: 5px;
  // }
}
