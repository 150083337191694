@import '../../../../../styles/variables';

.headerLabel {
  padding-right: 24px;
}

%breadcrumbStep {
  text-transform: capitalize;
}

.activeBreadcrumbStep {
  @extend %breadcrumbStep;
  color: $SecondaryColor !important;
}

.currentBreadcrumbStep {
  @extend %breadcrumbStep;
  color: $PrimaryColor !important;
  text-decoration: underline;
}

.inactiveBreadcrumbStep {
  @extend %breadcrumbStep;
  color: #CACACA;
}

// .blockedWarning {
//   border: solid 1px $SecondaryColor;
// }
