.card {
  padding: 24px !important;
}

.logicExplanation {
  * {
    display: block;
  }

  span:first-of-type {
    font-weight: bold;
  }

  span:not(:first-of-type) {
    padding-left: 8px;
  }
}