@import '../../../styles/variables';

.textItem {
  color: $WhiteColor;
  font-size: 15px;
  font-weight: bold;
}

.imageItem {
  align-items: center;
  background-color: $PrimaryColor;
  border-radius: 8px;
  display: flex !important;
  height: 198px;
  justify-content: center;
  width: 98% !important;
  &:focus {
    outline: none;
  }
}

.listItems {
  height: 198px;
  li button:before {
    color: $WhiteColor !important;
  }
}
