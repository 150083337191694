@import '../../../styles/variables';

.link {
  color: $PrimaryColor;
  cursor: pointer;
}

.linkWrapper {
  margin-top: 8px;
}
