@import '../../../../styles/variables';

.questionsColumn {
  padding-right: 0 !important;
  padding-top: 0 !important;
}

.detailsColumn {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.auditActionsRow {
  background-color: $FogColor;
  justify-content: flex-end !important;
  margin-top: 1em !important;
  padding-right: 2em !important;
}

.header {
  align-items: center;
  background-color: $FogColor;
  color: #5F8298;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  height: 50px;
  justify-content: center;
}

.scrollable {
  height: 1024px !important;
  overflow: scroll !important;
  padding: 0 10px 10px 10px;
}

.labelStyle {
  text-decoration: underline;
}

.valueStyle {
  font-size: 1em;
}
