@import '../../../../styles/guide';
@import '../../../../styles/servelegal-font-variables';

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;

  p {
    margin-bottom: 56px !important;
  }
}

.fireworksIcon {
  @extend %icon-before;
  color: $PrimaryColor;
  font-size: 120px;
  margin-bottom: 56px;

  &::before {
    content: $icon-fireworks;
    font-style: initial;
  }
}
