@import '../../../../../../styles/guide';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/servelegal-font-variables';

%header-class {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  >span {
    padding: 0 5px;
  }
}

.header {
  @extend %header-class;
}

.marginBottom {
  margin-bottom: 20px !important;
}

.removeCondition {
  @extend %icon;
  background-color: transparent;
  border: none;
  height: 40px;
  width: 40px;

  &::before {
    content: $icon_trash;
    cursor: pointer;
  }
}

.removeGroup {
  @extend %icon;
  align-items: center;
  background-color: #b5b5b5;
  border: none;
  border-radius: 10px;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-right: 18px;
  margin-top: -30px;
  width: 30px;

  &::before {
    color: white;
    content: $icon_close_thick;
    cursor: pointer;
  }
}

.removeRule {
  @extend %icon;
  align-items: center;
  background-color: #ef5268;
  border: none;
  border-radius: 100%;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 10px;
  width: 40px;

  &::before {
    color: white;
    content: $icon-trash;
    cursor: pointer;
  }
}

.box>div {
  min-width: 2em !important;
}

.flex {
  display: flex !important;
}

.noBorderRadiusLeft>div {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.noBorderRadiusRight>div>input {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.overflowSelect {
  left: -7em;
  position: relative;
  width: 100px;
}

.cardMargin {
  margin: 2em 0 !important;
}

.disabledConditionSelector {
  opacity: 1 !important;

  >div {
    background-color: #bfbfbf54 !important;
    border: none !important;
    opacity: 1 !important;
  }

  i {
    display: none;
  }
}