@import "../../../styles/variables";

.root {
  align-items: center;
  background: linear-gradient($PrimaryColor, $SecondaryColor);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  user-select: none;
  width: 100%;
}

.logoStrapline{
  background-image: url('../../../assets/images/strapline-transparent.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 120px;
  position: relative;
  width: 100%;
  z-index: 1;
}