@import '../../../../styles/guide';
@import '../../../../styles/variables';

.content {
  align-items: center;
  color: #959595;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
