@import '../../../../../styles/guide';
@import '../../../../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.basicInfo {
  align-items: center;
  color: $HelpColor;
  display: flex;
  flex-wrap: wrap;
  padding-right: 16px;
  width: auto;
}

.auditor {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.preferedName {
  @extend %h2;
  color: $SecondaryColor;
  font-weight: normal;
  line-height: 32px;
}

.accountTitle {
  font-weight: bold;
}

.account {
  @extend %h6;
}

.contact {
  margin-left: auto;
  margin-top: auto;
}

.image {
  background-color: $SecondaryColor !important;
  margin: 20px 0;
}

.image:hover {
  cursor: zoom-in;
}

.statistic {
  @extend %h6;
  color: $SecondaryColor;
  display: flex;
  flex-flow: column;
}

.notes {
  color: $SecondaryColor;
  margin-left: 20px;
  margin-top: -30px !important;
  @extend %h6;

  .accountTitle {
    @extend %h4;
  }
}

.notesfield {
  textarea {
    border: 2px solid $GrayColor;
    border-radius: 0.2rem;
    height: 100px;
    padding: 10px;
    resize: none;
    width: 400px;
  }
}