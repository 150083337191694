@import "../../../../styles/variables";
@import "../../../../styles/servelegal-font-variables";
@import "../../../../styles/guide";

.root {
  display: flex;
  flex-direction: column !important;
  height: 100%;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 40px;
  @media (max-width: $mobile-width) {
    padding: 0px;
  }
}

.formField {
  font-size: 14px;
  div {
    display: flex;
    flex-direction: column;
    margin: 5px 0px;
    label {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 50px;
  text-align: left;
  // width: 75%;
  width: 100%;
}

.title {
  font-size: 46px;
  font-weight: 600;
  margin-bottom: 40px;
}

.subtitle {
  font-size: 24px;
}

.buttons {
  display: flex;
  @media (max-width: $mobile-width) {
    flex-direction: column;
    :first-child{
      margin-bottom: 4px;
    }
  }
}

.client {
  font-style: italic;
  font-weight: 600;
}

.label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.contentDoc {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.iconPdf {
  @extend %icon-after;
  color: $PrimaryColor !important;
  margin-right: 16px;

  &::after {
    content: $icon-textarea;
    font-size: 13px;
  }
}

.textPdf {
  color: $SecondaryColor;
  cursor: pointer;
  text-decoration: underline;
}

// .sideImg {
//   background-image: url('../../../../assets/images/side-img.png');
//   background-position: right;
//   background-repeat: no-repeat;
//   background-size: cover;
//   border-radius: 0em 8px 8px 0em;
//   height: 100%;
//   width: 25%;
// }