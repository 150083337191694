@import "../../../../../styles/guide";
@import "../../../../../styles/variables";
@import "../../../../../styles/servelegal-font-variables";

.titleQuestion {
  color: $AuxiliarColor;
  font-size: 14px;
  margin: 8px 0px 16px 0px;
  text-align: center;
}

.italicTitleQuestion {
  @extend .titleQuestion;
  font-style: italic;
}