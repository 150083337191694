@import '../../../../../styles/variables';

%question {
  padding: 6px 12px;
}

.header {
  font-weight: 500;
}

.fixedAnswerQuestion {
  @extend %question;
}

.openAnswerQuestion {
  @extend %question;
  color: $HelpColor;
}

.option {
  padding-left: 12px;
}