@import "../../../../styles/guide";
@import "../../../../styles/variables";
@import "../../../../styles/servelegal-font-variables";

.subtitle {
  color: $AuxiliarColor;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-bottom: 24px;
  padding-top: 16px;
  text-align: center;
  width: 100%;

  .title {
    font-weight: bold;
  }
  .number {
    color: $PrimaryColor;
    padding-top: 8px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  line-height: 16px;
  width: 100%;
}