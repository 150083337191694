.formRecognitionForm {
  margin-top: 12px;
}

.formRecognitionValue {
  display: flex;
  flex-direction: column;
}

.formRecognitionSpan {
  margin: 5px 0px;
}
