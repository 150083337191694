@import '../../../styles/variables';

.textarea {
  textarea::placeholder {
    color: $HelpColor !important;
  }
}

.displayValue {
  white-space: pre-wrap;
}