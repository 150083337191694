.contentColumn {
  width: 300px !important;
}

.auditorsContainer {
  margin-bottom: 12px;
}

.tagContent {
  margin: 0px 4px 4px 0px !important;
}