@import '../../../../../../styles/guide';
@import '../../../../../../styles/servelegal-font-variables';
@import '../../../../../../styles/variables';

.modalContainer {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.modalTitle {
  text-align: center;
  width: 100%;
}

.form {
  width: 100%;
}

.options {
  margin-top: 10px;
}

.iconTrash {
  @extend %icon-before;
  font-size: 12px;

  &::before {
    content: $icon-trash;
  }
}

.error {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #9F3A38 !important;
  font-size: 0.85714286rem;
  font-weight: bold;
  white-space: normal;
}
