@import '../../../../../../styles/guide';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/servelegal-font-variables';

.column {
  padding: 4px !important;
}

.questionType {
  align-items: center;
  background-color: $FogColor;
  border-radius: 4px;
  color: $SecondaryColor;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  justify-content: flex-start;
  padding: 12px;

  &:hover {
    background-color: $WhiteColor;
  }
}

.questionTypeIcon {
  padding-right: 16px;
}