@import '../../../../styles/variables';

.root {
  color: $HelpColor;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.warning {
  color: $ErrorColor;
}

.success {
  color: #39be6c;
}

.error {
  color: #c84858;
}

.responseFirst {
  font-weight: 500;
  margin: 20px 5px 5px 5px;
}

.responseSecond {
  font-weight: 500;
  margin: 5px 5px;
}