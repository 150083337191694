@import "../../../../styles/guide";

.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

%input {
  width: 100%;
}

.emailInput {
  @extend %input;
}

.passwordInput {
  @extend %input;
}

.requestPasswordLink {
  @extend %h6;
  align-self: flex-end;
  padding-bottom: 36px;
}