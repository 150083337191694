@import "../../../../../styles/variables";
@import "../../../../../styles/servelegal-font-variables";
@import "../../../../../styles/guide";

.body {
  background-color: $FogColor;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 20px 24px 20px 24px;
}

.iconDownload {
  @extend %icon-after;
  margin-right: 9px;
  &::after {
    content: $icon-download;
    font-size: 10px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.buttonDownload {
  background-color: $AuxiliarColor !important;
  color: $WhiteColor !important;
  margin: 40px auto 0px auto !important;
  padding: 14px 24px !important;
}
