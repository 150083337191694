@import '../../../../styles/guide';
@import '../../../../styles/servelegal-font-variables';

.actions {
  display: flex;
  justify-content: space-between;
}

.expanded {
  @extend %icon;

  display: inline-block;
  transform: rotate(180deg);

  &::before {
    content: $icon-arrowDown_mid;
  }
}

.collapsed {
  @extend %icon;

  &::before {
    content: $icon-arrowDown_mid;
  }
}