/*******************************
         Site Overrides
*******************************/

.ui.big.button {
  border-radius: 24px;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  padding: 16px 67px;
}