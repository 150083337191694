@import '../../../styles/guide';
@import '../../../styles/servelegal-font-variables';

.nestedItem {
  span {
    padding-left: 20px;
  }
}

.textPdf {
  color: $SecondaryColor;
  cursor: pointer;
  text-decoration: underline;
}
