@import "../../../../../styles/variables";
@import "../../../../../styles/servelegal-font-variables";
@import "../../../../../styles/guide";

.box {
  display: flex;
  flex-direction: column !important;
  width: 100%;
}

.checkbox {
  background-color: $WhiteColor;
  box-shadow: none !important;
  margin-bottom: 15px;
  &:hover {
    background-color: #FFF8FA;
  }
  input:checked ~ label:before {
    background: $PrimaryColor;
    border-radius: 0.2rem;
    color: $WhiteColor;
  }
  input:checked ~ label:after {
    background: $PrimaryColor;
    border-radius: 0.2rem;
    color: $WhiteColor !important;
  }
}

.radio {
  background-color: $WhiteColor;
  box-shadow: none !important;
  cursor: pointer;
  margin-bottom: 15px;
  &:hover {
    background-color: #FFF8FA;
  }
  input:checked ~ label:before {
    background-color: $PrimaryColor !important;
    border-color: $PrimaryColor !important;
    color: $WhiteColor !important;
  }
  input:checked ~ label:after {
    background-color: $WhiteColor !important;
  }
}

.dropdown {
  width: 100%;
}
