@import "../../../styles/variables";
@import "../../../styles/guide";

$logo-height: 69px;

.root {
  background-color: $WhiteColor;
  min-height: 1024px;
  width: $navbar-width;
}

.logo {
  background-image: url('../../../assets/images/transparent-logo.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: $logo-height;
  width: 100%;
}

.navbar {
  display: flex;
  flex-direction: column;
  height: calc(100% - #{$logo-height});
  padding-top: 10px;
  width: 100%;
}

.divider {
  margin-top: auto !important;
}

.tab {
  align-items: center;
  color: $SecondaryColor;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.activeBackground {
  background: linear-gradient(90deg, rgba(238,72,95,1) 0%, rgba(95,130,152,1) 100%);
  border-radius: 8px 0 0 8px;
  height: 100%;
  left: 8px;
  position: absolute;
  width: calc(100% - 8px);
}

.activeTab {
  color: $WhiteColor;
}

.tabIcon {
  font-size: 1.5rem;
  padding-bottom: 3px;
  z-index: 1;
}

.tabLabel {
  font-weight: 500;
  padding: 0 10px;
  text-align: center;
  z-index: 1;
}
