@import '../../../../../styles/guide';
@import '../../../../../styles/servelegal-font-variables';

%action {
  color: $SecondaryColor;
  font-size: 13px;
  padding: 0 12px;
}

.back {
  @extend %icon-before;
  @extend %action;

  &::before {
    @extend %primary;
    content: $icon-arrowLeft;
  }
}