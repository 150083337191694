@import "../../../../styles/guide";
@import "../../../../styles/variables";
@import "../../../../styles/servelegal-font-variables";

.root {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.continueIcon {
  @extend %icon-after;
  background-color: transparent !important;
  border: none;
  color: $WhiteColor !important;
  outline: none;
  padding: 0 !important;

  &::after {
    content: $icon-arrowRight;
  }
}

.buttonFooter {
  display: flex;
  justify-content: center;
  padding-top: 22px;
  width: 100%;
}

.none {
  visibility: hidden;
}

.arrowButtons {
  bottom: 0;
  display: flex;
  justify-content: space-between; left: 0;
  margin:auto;
  position: absolute; right: 0;
  top: 0;
  visibility: inherit;
  width: 600px;
  z-index: 0;
  @media (max-width: $mobile-width) {
    width: 98%;
  }
}

.iconAccept {
  @extend %icon-after;

  &::after {
    content: $icon-check_thick;
    font-size: 10px;
  }
}

%arrow {
  @extend %icon-after;

  background-color: transparent !important;
  border: none;
  color: $WhiteColor;
  font-size: 2rem;
  outline: none;
  padding: 0 !important;
}

.back {
  @extend %arrow;

  &::after {
    content: $icon-arrowLeft;

    @media (max-width: $mobile-width) {
      background-color: $AuxiliarColor;
      border-radius: 8px;
      padding: 8px;
    }
  }
}

.next {
  @extend %arrow;

  &::after {
    content: $icon-arrowRight;

    @media (max-width: $mobile-width) {
      background-color: $AuxiliarColor;
      border-radius: 8px;
      padding: 8px;
    }
  }
}

.footer {
  font-size: 13px;
  padding-top: 22px;
  text-align: center;

  span {
    color: $AuxiliarColor;
    display: block;
    margin-bottom: 8px;
  }

  .contactUsLink {
    color: $SecondaryColor;
    text-decoration: underline;
  }
}
