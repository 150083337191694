@import "../../../../../styles/guide";
@import "../../../../../styles/servelegal-font-variables";

.help {
  @extend %input-help;
}

.tokensButton {
  margin-top: 8px !important;
}

.subFields {
  display: flex;
  flex-direction: column;

  >*:not(:last-child) {
    padding-bottom: 16px;
  }
}