.root {
  max-height: 100%;
  width: 100%;
}

.headerCell {
  font-size: 11px;
}

.clickableHeaderCell {
  &:hover {
    cursor: pointer !important;
  }
}

.shortIconWrapper {
  padding-left: 6px;
}

.pagination {
  align-items: center;
  display: flex;
  font-weight: bold;
}

.pageSizeSelector {
  align-items: center;
  display: flex;
  padding: 0 10px;
}

.pageSizeSelectorLabel {
  padding-right: 5px;
}
