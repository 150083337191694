@import '../../../../../../styles/guide';
@import '../../../../../../styles/servelegal-font-variables';

.remove {
  @extend %icon;
  cursor: pointer;

  &::before {
    content: $icon-trash;
  }
}

.questionsReference {
  padding-top: 40px;
}

.scroll {
  height: 85vh;
  overflow-x: hidden;
  overflow-y: auto;
}