@import "../../../../../styles/guide";
@import "../../../../../styles/variables";
@import "../../../../../styles/servelegal-font-variables";

.textImages {
  color: $AuxiliarColor;
  font-size: 14px;
  margin: 8px 0px 16px 0px;
  text-align: left;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin: 30px 0px 20px;
  padding: 0px 50px;
}

.logo {
  height: 65px;
}