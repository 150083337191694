.calendarInput {
  div {
    width: auto;
  }
}
.interval {
  display: flex;
  flex-direction: row;
  div {
    margin: 0px;
    width: 100%;
  }
  div:first-child {
    margin-right: 5px;
  }
}

.intervalWrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.pickerWrapper {
  max-width: 250px;
}