.content {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.actions{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.imageUpload {
  padding-top: 20px!important;
}