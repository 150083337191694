@import '../../../../styles/guide';
@import '../../../../styles/servelegal-font-variables';

.save {
  @extend %icon-before;

  &::before {
    @extend %primary;
    color: $WhiteColor;
    content: $icon-save;
  }
}
