@import '../../../../styles/guide';
@import '../../../../styles/variables';

.total {
  font-weight: 500;
}

.radioToolbar {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.selectedOperation {
  color: $PrimaryColor;
}

.tableContainer {
  background-color: $WhiteColor;
  border: 1px solid $AuxiliarColor;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 30px;
}

.adjustmentField {
  width: 68px;
}