@import '../../../../../../../styles/guide';
@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/servelegal-font-variables';

%header-class {
  display: flex;
  justify-content: flex-end;

  visibility: hidden;

  > span {
    padding: 0 5px;
  }
}

.header {
  @extend %header-class;
}

.selected {
  background-color: #F8F8F8;

  .header {
    @extend %header-class;
    visibility: visible;
  }
}

.root {
  width: 100%;

  &:hover {
    background-color: #F8F8F8;

    .header {
      @extend %header-class;
      visibility: visible;
    }
  }
}

.remove {
  @extend %icon;

  &::before {
    content: $icon-trash;
  }
}

.content {
  display: flex;
}

.questionContainer {
  flex: 1;
  padding: 5px;
}
