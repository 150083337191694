@import '../../../../../styles/guide';
@import '../../../../../styles/servelegal-font-variables';
@import '../AuditsTableHeader.module';

.upload {
  @extend %icon-before;
  @extend %action;

  &::before {
    @extend %primary;
    content: $icon-upload_cloud;
  }
}

.toggleIconOpen {
  @extend %toggle-icon;
  background-color: $SecondaryColor;

  &::before {
    content: $icon-arrowUp;
  }
}

.toggleIconClosed {
  @extend %toggle-icon;
  background: linear-gradient($SecondaryColor, $PrimaryColor);

  &::before {
    content: $icon-settings;
  }
}
