@import '../../../../../../styles/variables';

.containerTable {
  margin-bottom: 1em !important;
}

.bodyTable {
  max-height: 355px;
  overflow-y: scroll;
}

.header {
  background-color: $AuxiliarColor;
  color: $WhiteColor;
  font-size: 16px;
  font-weight: bold;
  padding: 11px 0px;
}

.leftCell {
  font-weight: bold;
}

.rightCell {
  min-width: 160px;
}

.tableRow:hover {
  cursor: pointer;
}
