@import '../../../../../../../styles/guide';
@import '../../../../../../../styles/servelegal-font-variables';
@import '../../../../../../../styles/variables';

.hidden {
  display: none !important;
}

.hiddenQuestion {
  color: $PrimaryColor;
}

%row-icon {
  @extend %icon;
  cursor: pointer;
}

.visibleIcon {
  @extend %row-icon;

  &::before {
    content: $icon-visibility;
  }
}

.hiddenIcon {
  @extend %row-icon;

  &::before {
    content: $icon-visibility_off;
  }
}