@import '../../../styles/variables';

.root {
  background-color: $SecondaryColor;
  display: flex;
  height: 100%;
  max-height: 100%;
  min-height: $navbar-min-height;
  width: 100%;
}

.card {
  align-items: center;
  background: linear-gradient(180deg, #f7f9fb 0%, #ebf0f5 100%);
  border-radius: 8px;
  box-shadow: 0 10px 30px 0 rgba(43, 49, 64, 0.06);
  display: flex;
  flex-direction: column;
  height: 30%;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20%;
  padding: 32px 40px;
  width: 444px;
}

.title {
  font-size: 28px;
  font-weight: bold;
  line-height: 46px;
}

.router {
  flex: 1;
  max-height: 100%;
  overflow-y: auto;
  padding: 16px;
}

.rootMobile {
  background-color: $SecondaryColor;
  display: flex;
  height: calc(100% - 48px);
  max-height: 100%;
  width: 100%;
}