.datePickerContainer {
  position: relative;
}

.calendarInput {
  margin-bottom: 0 !important;
}

.calendarPopup {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: grid;
  left: 0;

  position: absolute;
  top: calc(100% + 1px);
  width: 240px;
  z-index: 999;
}

.calendarHeader {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 3fr 1fr;
  padding: 10px 0;
  text-align: center;

  .calendarHeaderArrow {
    cursor: pointer;
  }

  .calendarHeaderDisabledArrow {
    color: #ccc;
    cursor: default;
  }

  .calendarHeaderClickable {
    cursor: pointer;
  }

  .calendarHeaderClickable:hover {
    font-weight: bold;
  }
}

.calendarBodyDays {
  align-items: center;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  padding: 0 10px 15px 10px;
  text-align: center;

  .dayName {
    font-size: 13px;
    font-weight: bold;
  }

  .weekDay {
    cursor: pointer;
  }

  .weekDay:hover {
    background-color: #5F8298;
    border-radius: 20%;
    color: white;
  }

  .weekendDay {
    color: #fc5858;
    cursor: pointer;
  }

  .weekendDay:hover {
    background-color: #5F8298;
    border-radius: 20%;
    color: white;
  }

  .emptyDay {
    cursor: default;
    height: 20px;
    width: 20px;
  }

  .disabledDay {
    color: #ccc;
    cursor: default;
  }

  .selectedDay {
    background-color: #5F8298;
    border-radius: 20%;
    color: white;
  }

  .selectedDay:hover {
    cursor: pointer;
  }
}

.calendarBodyMonths {
  align-items: center;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  padding: 0 10px 15px 10px;
  text-align: center;

  .month {
    cursor: pointer;
    padding: 6px 3px;
  }

  .month:hover {
    background-color: #5F8298;
    border-radius: 5%;
    color: white;
  }
}

.calendarBodyYears {
  align-items: center;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  padding: 0 10px 15px 10px;
  text-align: center;

  .year {
    cursor: pointer;
    padding: 6px 3px;
  }

  .year:hover {
    background-color: #5F8298;
    border-radius: 5%;
    color: white;
  }
}

.calendarTimeBody {
  align-items: center;
  display: inline-grid;
  grid-gap: 3px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 2fr 1fr;
  justify-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.calendarTimeInput {
  text-align: center;
  width: 50px;
  input {
    -moz-appearance: textfield!important;
    appearance: textfield!important;
  }
}

.calendarTimeArrow {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0 !important;

  &:hover{
    background-color: #5F8298;
    border-radius: 20%;
    color: white;
  }
}

.mobileButtons {
  display: flex;
  gap: 5px;
  justify-content: center;
  padding: 10px;
}

input[type=number] {
  -moz-appearance: textfield;
}
