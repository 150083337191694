@import '../../../styles/guide';
@import '../../../styles/servelegal-font-variables';
@import '../../../styles/variables';

.reclaimableExpensesTitle {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.cardCollapsed {
  padding: 5px 10px !important;
  width: 100% !important;
}

.cardOpened {
  padding: 5px 10px 15px 10px !important;
  width: 100% !important;
}

.accordionTitleWrapper {
  cursor: default !important;
  display: flex;
  justify-content: space-between;
}

.accordionTitle {
  align-items: center;
  cursor: pointer;
  display: flex;
  width: 100%;
}

.reclaimableExpensesAddIcon:hover {
  cursor: pointer;
}

.reclaimableExpensesDeleteIcon:hover {
  cursor: pointer;
}

.addButtonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
