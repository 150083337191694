@import '../../../styles/variables';

.success {
  color: #39be6c;
}

.error {
  color: #c84858;
}

.responseFirst {
  font-weight: 500;
  margin: 20px 5px 5px 5px;
}

.responseSecond {
  font-weight: 500;
  margin: 5px 5px;
}