/*******************************
    User Variable Overrides
*******************************/

.ui.cards > .card > [class*="secondary"],
.ui.card > [class*="secondary"] {
  background-color: #F7F9FB;
}

.ui.cards > .card > [class*="condensed"],
.ui.card > [class*="condensed"] {
  padding: 0;
}
