/*******************************
         Site Overrides
*******************************/

.ui.modal > .header {
  border-bottom: 0;
  display: flex;
  justify-content: center;
}

.ui.modal > .content {
  display: flex;
  justify-content: center;
}

.ui.modal > .actions {
  border-top: 0;
  background: @contentBackground;
  display: flex;
  justify-content: center;
}