@import '../../../../styles/guide';
@import '../../../../styles/servelegal-font-variables';

%action {
  color: $SecondaryColor;
  font-size: 13px;
  padding: 0 12px;
}

%toggle-icon {
  @extend %icon;
  border-radius: 0 0 8px 8px;
  color: white;
  font-size: 1.5rem;
  margin-top: -10.5px;
  padding: 1rem 1.5rem;
}
