@import '../../../../../styles/guide';
@import '../../../../../styles/servelegal-font-variables';

.row {
  padding: 0px 16px;
}

.rowDouble {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 16px;
}

.hidden {
  display: none;
}

.containerButton {
  display: flex;
  justify-content: center;
}

.deadline {
  color: $PrimaryColor;
}

.locationIcon {
  @extend %icon-before;
  font-size: 14px;

  &::before {
    content: $icon-visits;
  }
}

.deadlineIcon {
  @extend %icon-before;
  color: $PrimaryColor;
  font-size: 12px;

  &::before {
    content: $icon-deadline;
  }
}

.estimateIcon {
  @extend %icon-before;
  font-size: 12px;

  &::before {
    content: $icon-estimate;
  }
}

.mileageIcon {
  @extend %icon-before;
  font-size: 12px;

  &::before {
    content: $icon-mileage;
  }
}

.currencyIcon {
  @extend %icon-before;
  font-size: 12px;

  &::before {
    content: $icon-currency;
  }
}

.iconList {
  @extend %icon-before;
  font-size: 12px;

  &::before {
    content: $icon-radio;
  }
}

.siteIcon {
  @extend %icon-before;
  font-size: 12px;

  &::before {
    content: $icon-sites;
  }
}

.paysAssignedContainer {
  display: flex;
  flex-direction: column;
}

.paysFinishedContainer {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
