$icomoon-font-family: "servelegal" !default;
$icomoon-font-path: "../assets/fonts" !default;

$icon-map: "\e95b";
$icon-location_home: "\e958";
$icon-location_term: "\e959";
$icon-location_auditor: "\e95a";
$icon-location: "\e957";
$icon-no-location: "\e956";
$icon-auditor-application: "\e955";
$icon-folder: "\e954";
$icon-mileage: "\e951";
$icon-deadline: "\e952";
$icon-estimate: "\e953";
$icon-send: "\e950";
$icon-visits_current: "\e94e";
$icon-doc_pdf: "\e94f";
$icon-add_square: "\e900";
$icon-add: "\e901";
$icon-alcohol: "\e902";
$icon-arrowDown_mid: "\e903";
$icon-arrowDown: "\e904";
$icon-arrowLeft_mid: "\e905";
$icon-arrowLeft: "\e906";
$icon-arrowRight: "\e907";
$icon-arrowUp: "\e908";
$icon-check_thick: "\e909";
$icon-checkbox: "\e90a";
$icon-clients: "\e90b";
$icon-close_thick: "\e90c";
$icon-cog_fill: "\e90d";
$icon-cog_stroke: "\e90e";
$icon-currency: "\e90f";
$icon-date: "\e910";
$icon-datetime: "\e911";
$icon-deliveryman: "\e912";
$icon-dots: "\e913";
$icon-dots2: "\e914";
$icon-download: "\e915";
$icon-dropdown: "\e916";
$icon-duplicate: "\e917";
$icon-edit: "\e918";
$icon-empty-form-builder: "\e919";
$icon-export_fill: "\e91a";
$icon-eye: "\e91b";
$icon-eye2: "\e91c";
$icon-fieldset: "\e91d";
$icon-fireworks: "\e91e";
$icon-gambling: "\e91f";
$icon-generic: "\e920";
$icon-image: "\e921";
$icon-interval: "\e922";
$icon-list: "\e923";
$icon-loading: "\e924";
$icon-lock: "\e925";
$icon-logic: "\e926";
$icon-logout: "\e927";
$icon-lottery: "\e928";
$icon-media_research: "\e929";
$icon-mobile_phone: "\e92a";
$icon-move: "\e92b";
$icon-order_asc: "\e92c";
$icon-order_desc: "\e92d";
$icon-order: "\e92e";
$icon-orders: "\e92f";
$icon-patches: "\e930";
$icon-pets: "\e931";
$icon-pills: "\e932";
$icon-porn: "\e933";
$icon-pub: "\e934";
$icon-radio: "\e935";
$icon-reload: "\e936";
$icon-remove: "\e937";
$icon-save: "\e938";
$icon-select: "\e939";
$icon-settings: "\e93a";
$icon-sites: "\e93b";
$icon-surveys: "\e93c";
$icon-taxonomy: "\e93d";
$icon-text: "\e93e";
$icon-textarea: "\e93f";
$icon-time: "\e940";
$icon-title: "\e941";
$icon-tobacco: "\e942";
$icon-trash: "\e943";
$icon-upload_cloud: "\e944";
$icon-upload: "\e945";
$icon-user: "\e946";
$icon-users: "\e947";
$icon-view_all: "\e948";
$icon-visibility_off: "\e949";
$icon-visibility: "\e94a";
$icon-visitors: "\e94b";
$icon-visits: "\e94c";
$icon-weapons: "\e94d";
$icon-archive: "\e95c";