@import '../../../../styles/variables';
@import '../../../../styles/guide';

.mapPanel {
  border-radius: 8px !important;
  height: 250px;
  margin-bottom: 16px;
  @media (max-width: $mobile-width) {
    height: 350px;
  }
}

.map {
  border-radius: 8px;
  height: 100%;
  width: 100%;
}

.selectTitle {
  color: $SecondaryColor;
  font-weight: 500;
  margin-bottom: 3px;
}

.labelDirections {
  margin-top: 8px !important;
  width: 100%;
  span {
    font-weight: normal !important;
  }
}