@import '../../../../../../../../styles/guide';
@import '../../../../../../../../styles/servelegal-font-variables';
@import '../../../../../../../../styles/variables';

.grid {
  margin: 0 !important;
  width: 100%;
}

%action-icon {
  @extend %icon;
  cursor: pointer;
}

// .move {
//   @extend %action-icon;

//   &::before {
//     content: $icon-move;
//   }
// }

.delete {
  @extend %action-icon;

  &::before {
    content: $icon-trash;
  }
}

.add {
  @extend %action-icon;

  &::before {
    content: $icon-add_square;
  }
}

.remove {
  @extend %action-icon;
  background-color: $SecondaryColor;
  border-radius: 3px;
  color: $WhiteColor;
  font-size: 12px;
  padding: 3px 5px;

  &::before {
    content: $icon-trash;
    margin-right: 5px;
  }
}
