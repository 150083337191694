@import '../../../../../styles/guide';
@import '../../../../../styles/servelegal-font-variables';
@import '../../../../../styles/variables';

.iconDelete {
  @extend %icon-before;
  font-size: 11px;
  opacity: 0.7;
  transition: opacity 0.1s ease;

  &::before {
    content: $icon-trash;
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.iconEdit {
  @extend %icon-before;
  font-size: 11px;
  opacity: 0.7;
  transition: opacity 0.1s ease;

  &::before {
    content: $icon-edit;
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.pdfEditorTitle{
  text-align: center;
}

.labelText {
  margin: 0 7px;

  &:hover {
    cursor: pointer;
  }
}

.form {
  > * {
    margin-bottom: 10px;
  }
}

.tagsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
}
