@import "../../../../../styles/guide";
@import "../../../../../styles/variables";
@import "../../../../../styles/servelegal-font-variables";

.titleQuestion {
  color: $AuxiliarColor;
  font-size: 14px;
  margin: 8px 0px 16px 0px;
  text-align: center;
  width: 100%;
}

.noteQuestion {
  color: $AuxiliarColor;
  font-size: 13px;
  margin: 0em 0em 1em;
  text-align: left;
}

.noteQuestionItalic {
  color: $AuxiliarColor;
  font-size: 13px;
  font-style: italic;
  margin: 0em 0em 1em;
  text-align: left;
}

.image{
  height: auto;
  margin: 10px auto;
  width: 80%;
}
