@import '../../../../styles/guide';
@import '../../../../styles/servelegal-font-variables';

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: center;
  text-align: center;

  p {
    margin-bottom: 48px !important;
  }
}

.positionIcon {
  @extend %icon-before;
  margin-left: 16px;

  &::before {
    color: #fff;
    content: $icon-visits_current;
  }
}