.surveyReviewContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
}

.submitButton {
  margin-top: 16px !important;
}

.title {
  font-weight: bold;
}
