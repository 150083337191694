.auditKeyInfoStyle {
  font-size: 1.2em !important;
}

.boldText {
  font-size: 1.2em !important;
  font-weight: bold;
  padding-top: 3em;
}

.approvalGreen {
  color: limegreen;
  font-size: 1.2em !important;
  padding-top: 3em;
  text-transform: uppercase;
}

.denialRed {
  color: red;
  font-size: 1.2em !important;
  padding-top: 3em;
  text-transform: uppercase;
}
