@import '../../../../styles/guide';
@import '../../../../styles/variables';

.tableContainer {
  background-color: $WhiteColor;
  border: 1px solid $AuxiliarColor;
  border-radius: 4px;
  box-sizing: border-box;
  height: 260px;
  margin-top: 30px;
  overflow-y: auto;
  padding: 25px;
  width: 588px;
}