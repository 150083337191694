@import "../../../../../styles/guide";
@import "../../../../../styles/variables";
@import "../../../../../styles/servelegal-font-variables";

.input {
  width: 100%;
}

.termsAndConditionsLink {
  cursor: pointer;
  text-decoration: underline;
}