@import '../../../../styles/guide';
@import '../../../../styles/servelegal-font-variables';

.save {
  @extend %icon-before;

  &::before {
    @extend %primary;
    color: $WhiteColor;
    content: $icon-save;
  }
}

%action {
  color: $SecondaryColor;
  font-size: 13px;
  padding: 0 12px;
}

.revisions {
  @extend %icon-before;
  @extend %action;

  &::before {
    @extend %primary;
    content: $icon-list;
  }
}