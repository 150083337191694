@import '../../../../../styles/guide';
@import '../../../../../styles/servelegal-font-variables';
@import '../../../../../styles/_variables';

.menuContainer {
  background-color: transparent !important;
}

.containerSearch {
  background-color: $GrayColor;
  padding: 20px 24px;
}

.linkButton {
  background-color: $GrayColor;
  display: flex;
  justify-content: center;
  margin-top: 0.5em;
  padding: 0.2em 0;
  width: 100%;
}
