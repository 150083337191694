@import '../../../../../styles/guide';
@import '../../../../../styles/servelegal-font-variables';

.button {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.itemList {
  cursor: pointer;
  text-decoration: underline;
}