@import "../../../../styles/variables";
@import "../../../../styles/servelegal-font-variables";
@import "../../../../styles/guide";

.form {
  display: grid;
  gap: 15px;
  grid-template-columns: 5px 1fr 10px;
  height: 100%;
  width: 100%;
}

.innerForm {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.back {
  @extend %icon-after;
  align-items: center;
  background-color: transparent !important;
  border: none;
  color: $SecondaryColor !important;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  outline: none;
  width: 5px;
  &:disabled{
    cursor: default;
  }
  &::after {
    content: $icon-arrowLeft;
  }
}

.hidden {
  visibility: hidden;
}

.next {
  @extend %icon-after;
  align-items: center;
  background-color: transparent !important;
  border: none;
  color: $PrimaryColor !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  width: 5px;
  &:disabled{
    cursor: default;
  }
  &::after {
    content: $icon-arrowRight;
  }
}

.activePageNumber {
  color: $PrimaryColor;
  font-weight: bold;
  margin-bottom: 12px;
}

.buttons {
  display: flex;
  @media (max-width: $mobile-width) {
    flex-direction: column;
    :first-child{
      margin-bottom: 4px;
    }
  }
}