@import "../../../../../styles/variables";
@import "../../../../../styles/servelegal-font-variables";
@import "../../../../../styles/guide";

.dropZone {
  align-items: center;
  background-color: $WhiteColor;
  border-color: rgb(238, 238, 238);
  border-style: dashed;
  border-width: 1px;
  -webkit-box-align: center;
  color: $AuxiliarColor;
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: center;
  padding: 20px;
  width: 100%;

  p span {
    font-weight: bold;
  }

  .imageIcon {
    @extend %icon-after;
    margin-bottom: 15px;

    &::after {
      content: $icon-image;
      font-size: 50px;
    }
  }
}

.thumb {
  display: flex;
  flex-direction: row;
}

.thumbContainer {
  height: 120px;
  margin: 15px 5px;
  width: 120px;
}

.img {
  border-radius: 5px;
  max-height: 100%;
  max-width: 100%;
}
