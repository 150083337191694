@import '../../../../styles/guide';
@import '../../../../styles/servelegal-font-variables';

.edit {
  @extend %icon-before;

  &::before {
    content: $icon-edit;
  }
}

.modal{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 500px;
}

.scrollBox{
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 20px;
  min-height: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px;
  z-index: 10;
}