.label {
  font-weight: 500 !important;
  margin: 0 0 4px !important;
}

.textArea {
  margin: 0 0 14px !important;
}

.regExpLabel {
  >a {
    padding: 0 10px;
  }
}
