@import "../../../../../styles/guide";
@import "../../../../../styles/variables";
@import "../../../../../styles/servelegal-font-variables";

.titleQuestion {
  color: $AuxiliarColor;
  font-size: 14px;
  margin: 8px 0px 16px 0px;
  text-align: center;
  width: 100%;
}

// .radio {
//   align-items: center;
//   background-color: $WhiteColor;
//   border-radius: 0.2rem;
//   display: flex;
//   height: 50px;
//   margin-bottom: 15px;
//   padding-left: 15px;
//   width: 100%;

//   input:checked ~ label:before {
//     background-color: $PrimaryColor !important;
//     color: $WhiteColor !important;
//   }
//   input:checked ~ label:after {
//     background-color: $WhiteColor !important;
//   }
// }