@import '../../../../../styles/guide';
@import '../../../../../styles/servelegal-font-variables';

.newTermButton {
  margin-top: 10px !important;
}

.deleteButton {
  cursor: pointer;
  margin-left: 30px;
}

.addButton {
  cursor: pointer;
}

.parentRowText {
  max-width: 300px;
}

.subRowText {
  margin-left: 50px;
  max-width: 300px;
}
