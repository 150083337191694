@import '../../../../../styles/guide';
@import '../../../../../styles/servelegal-font-variables';
@import '../../../../../styles/_variables';

.menuContainer {
  background-color: transparent !important;
}

.containerSearch {
  background-color: $GrayColor;
  padding: 20px 24px;
}