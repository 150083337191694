@import "servelegal-font-variables";

@font-face {
  font-display: block;
  font-family: "#{$icomoon-font-family}";
  font-style: normal;
  font-weight: normal;
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?icl6cr");
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?icl6cr#iefix")
    format("embedded-opentype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?icl6cr")
    format("truetype"),
    url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?icl6cr")
    format("woff"),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?icl6cr##{$icomoon-font-family}')
    format('svg');
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;

  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale; // stylelint-disable-line order/properties-alphabetical-order
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

.icon-map {
  &:before {
    content: $icon-map;
  }
}
.icon-location_home {
  &:before {
    content: $icon-location_home;
  }
}
.icon-location_term {
  &:before {
    content: $icon-location_term;
  }
}
.icon-location_auditor {
  &:before {
    content: $icon-location_auditor;
  }
}
.icon-location {
  &:before {
    content: $icon-location;
  }
}
.icon-no-location {
  &:before {
    content: $icon-no-location;
  }
}
.icon-auditor-application {
  &:before {
    content: $icon-auditor-application;
  }
}
.icon-folder {
  &:before {
    content: $icon-folder;
  }
}
.icon-mileage {
  &:before {
    content: $icon-mileage;
  }
}
.icon-deadline {
  &:before {
    content: $icon-deadline;
  }
}
.icon-estimate {
  &:before {
    content: $icon-estimate;
  }
}
.icon-send {
  &:before {
    content: $icon-send;
  }
}
.icon-visits_current {
  &:before {
    content: $icon-visits_current;
  }
}
.icon-doc_pdf {
  &:before {
    content: $icon-doc_pdf;
  }
}
.icon-generic {
  &:before {
    content: $icon-generic;
  }
}
.icon-duplicate {
  &:before {
    content: $icon-duplicate;
  }
}
.icon-logout {
  &:before {
    content: $icon-logout;
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-checkbox {
  &:before {
    content: $icon-checkbox;
  }
}
.icon-currency {
  &:before {
    content: $icon-currency;
  }
}
.icon-date {
  &:before {
    content: $icon-date;
  }
}
.icon-datetime {
  &:before {
    content: $icon-datetime;
  }
}
.icon-fieldset {
  &:before {
    content: $icon-fieldset;
  }
}
.icon-image {
  &:before {
    content: $icon-image;
  }
}
.icon-interval {
  &:before {
    content: $icon-interval;
  }
}
.icon-radio {
  &:before {
    content: $icon-radio;
  }
}
.icon-select {
  &:before {
    content: $icon-select;
  }
}
.icon-text {
  &:before {
    content: $icon-text;
  }
}
.icon-textarea {
  &:before {
    content: $icon-textarea;
  }
}
.icon-time {
  &:before {
    content: $icon-time;
  }
}
.icon-title {
  &:before {
    content: $icon-title;
  }
}
.icon-alcohol {
  &:before {
    content: $icon-alcohol;
  }
}
.icon-deliveryman {
  &:before {
    content: $icon-deliveryman;
  }
}
.icon-fireworks {
  &:before {
    content: $icon-fireworks;
  }
}
.icon-gambling {
  &:before {
    content: $icon-gambling;
  }
}
.icon-lottery {
  &:before {
    content: $icon-lottery;
  }
}
.icon-media_research {
  &:before {
    content: $icon-media_research;
  }
}
.icon-pets {
  &:before {
    content: $icon-pets;
  }
}
.icon-pills {
  &:before {
    content: $icon-pills;
  }
}
.icon-porn {
  &:before {
    content: $icon-porn;
  }
}
.icon-pub {
  &:before {
    content: $icon-pub;
  }
}
.icon-tobacco {
  &:before {
    content: $icon-tobacco;
  }
}
.icon-view_all {
  &:before {
    content: $icon-view_all;
  }
}
.icon-weapons {
  &:before {
    content: $icon-weapons;
  }
}
.icon-arrowDown_mid {
  &:before {
    content: $icon-arrowDown_mid;
  }
}
.icon-arrowDown {
  &:before {
    content: $icon-arrowDown;
  }
}
.icon-arrowLeft_mid {
  &:before {
    content: $icon-arrowLeft_mid;
  }
}
.icon-arrowLeft {
  &:before {
    content: $icon-arrowLeft;
  }
}
.icon-arrowRight {
  &:before {
    content: $icon-arrowRight;
  }
}
.icon-arrowUp {
  &:before {
    content: $icon-arrowUp;
  }
}
.icon-clients {
  &:before {
    content: $icon-clients;
  }
}
.icon-logic {
  &:before {
    content: $icon-logic;
  }
}
.icon-orders {
  &:before {
    content: $icon-orders;
  }
}
.icon-patches {
  &:before {
    content: $icon-patches;
  }
}
.icon-sites {
  &:before {
    content: $icon-sites;
  }
}
.icon-surveys {
  &:before {
    content: $icon-surveys;
  }
}
.icon-taxonomy {
  &:before {
    content: $icon-taxonomy;
  }
}
.icon-users {
  &:before {
    content: $icon-users;
  }
}
.icon-visitors {
  &:before {
    content: $icon-visitors;
  }
}
.icon-visits {
  &:before {
    content: $icon-visits;
  }
}
.icon-add_square {
  &:before {
    content: $icon-add_square;
  }
}
.icon-add {
  &:before {
    content: $icon-add;
  }
}
.icon-check_thick {
  &:before {
    content: $icon-check_thick;
  }
}
.icon-close_thick {
  &:before {
    content: $icon-close_thick;
  }
}
.icon-cog_fill {
  &:before {
    content: $icon-cog_fill;
  }
}
.icon-cog_stroke {
  &:before {
    content: $icon-cog_stroke;
  }
}
.icon-dots {
  &:before {
    content: $icon-dots;
  }
}
.icon-dots2 {
  &:before {
    content: $icon-dots2;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-dropdown {
  &:before {
    content: $icon-dropdown;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-export_fill {
  &:before {
    content: $icon-export_fill;
  }
}
.icon-eye {
  &:before {
    content: $icon-eye;
  }
}
.icon-eye2 {
  &:before {
    content: $icon-eye2;
  }
}
.icon-list {
  &:before {
    content: $icon-list;
  }
}
.icon-loading {
  &:before {
    content: $icon-loading;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-mobile_phone {
  &:before {
    content: $icon-mobile_phone;
  }
}
.icon-move {
  &:before {
    content: $icon-move;
  }
}
.icon-order_asc {
  &:before {
    content: $icon-order_asc;
  }
}
.icon-order_desc {
  &:before {
    content: $icon-order_desc;
  }
}
.icon-order {
  &:before {
    content: $icon-order;
  }
}
.icon-reload {
  &:before {
    content: $icon-reload;
  }
}
.icon-remove {
  &:before {
    content: $icon-remove;
  }
}
.icon-save {
  &:before {
    content: $icon-save;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-upload_cloud {
  &:before {
    content: $icon-upload_cloud;
  }
}
.icon-upload {
  &:before {
    content: $icon-upload;
  }
}

.icon-archive {
  &:before {
    content: $icon-archive;
  }
}