@import '../../../../../styles/guide';
@import '../../../../../styles/servelegal-font-variables';

.nestedRows {
  padding-left: 30px;
}

.taxInput {
  div > input {
    width: 500px;
  }
}

.newTermButton {
  margin-top: 10px !important;
}

.add {
  @extend %icon-before;
  &::before {
    @extend %primary;
    content: $icon-add_square;
  }
  cursor: pointer;
}

.save {
  cursor: pointer;
}