@import '../../../styles/variables';
@import '../../../styles/guide';

$logo-height: 150px;

.root {
  align-items: center;
  background: linear-gradient($PrimaryColor, $SecondaryColor);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.logo {
  background-image: url('../../../assets/images/logo-name.svg');
  background-position: center 60%;
  background-repeat: no-repeat;
  background-size: 300px;
  height: $logo-height;
  width: 100%;
}

.card {
  align-items: center;
  background: linear-gradient(180deg, #f7f9fb 0%, #ebf0f5 100%);
  border-radius: 8px;
  box-shadow: 0 10px 30px 0 rgba(43, 49, 64, 0.06);
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
  width: 444px;
  @media (max-width: $mobile-width) {
    width: 90%;
  }
}

.title {
  font-size: 28px;
  font-weight: bold;
  line-height: 46px;
  text-align: center;
}

.subtitle {
  color: $AuxiliarColor;
  font-size: 14px;
  padding-top: 16px;
  text-align: center;

  a {
    font-weight: bold;
  }

  * {
    line-height: 16px;

    &:not(:last-child) {
      padding-bottom: 8px;
    }
  }
}

.formContainer {
  padding-top: 48px;
  width: 100%;

  input {
    height: 48px;
  }
}

.footer {
  font-size: 13px;
  padding-top: 42px;
  text-align: center;

  span {
    color: $AuxiliarColor;
    display: block;
    margin-bottom: 8px;
  }

  .contactUsLink {
    color: $SecondaryColor;
    text-decoration: underline;
  }
}
