@import "variables";

%title {
  font-size: 3.0rem;  // 48px
  font-weight: 400;
}

%h1 {
  font-size: (36 / 14) * 1rem;
  font-weight: 600;
}

%h2 {
  font-size: (24 / 14) * 1rem;
  font-weight: 600;
}

%h3 {
  font-size: (20 / 14) * 1rem;
  font-weight: 600;
}

%h4 {
  font-size: (17 / 14) * 1rem;
  font-weight: 600;
}

%h5 {
  font-size: (14 / 14) * 1rem;
  font-weight: 500;
}

%h6 {
  font-size: (13 / 14) * 1rem;
  font-weight: 500;
}

%icon {
  &::before {
    font-family: 'servelegal', sans-serif;
  }
}

%icon-before {
  &::before {
    font-family: 'servelegal', sans-serif;
    padding-right: 8px;
  }
}

%icon-after {
  &::after {
    font-family: 'servelegal', sans-serif;
    padding-left: 8px;
  }
}

%primary {
  color: $PrimaryColor;
}

%input-help {
  color: $HelpColor;
  font-size: 13px;
  font-weight: 300;
  padding-top: 8px;
}

%input-label-right-align {
  text-align: right;
}

%table-link {
  color: $SecondaryColor;
  text-decoration: underline;
}