@import '../../../../../styles/guide';
@import '../../../../../styles/variables';

.basicInfo {
  color: $HelpColor;
  display: flex;
}

.auditor {
  display: flex;
  flex-direction: column;
  padding: 16px 57px;
}

.name {
  @extend %h3;
  color: $SecondaryColor;
  font-weight: normal;
  line-height: 32px;
}

.age {
  @extend %h5;
}

.account {
  @extend %h5;
  margin-top: auto;
}

.contact {
  margin-left: auto;
  margin-top: auto;
}

.image {
  background-color: $SecondaryColor !important;
}