@import '../../../../../../styles/guide';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/servelegal-font-variables';

.content {
  display: grid;
  gap: 9px;
  grid-template-columns: repeat(3, 1fr);
}

.questionPackage {
  background-color: $FogColor;
  border-radius: 8px;
  color: $SecondaryColor;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  justify-content: space-evenly;
  padding: 16px;
  text-align: center;

  i {
    font-size: 2.5rem;
    padding-bottom: 10px;
  }

  &:hover {
    background-color: $WhiteColor;
  }
}

.questionContainer {
  padding: 5px 0;
}

.question {
  background-color: $FogColor;
  border-radius: 4px;
  color: $SecondaryColor;
  cursor: pointer;
  font-size: 13px;
  padding: 12px;

  &:hover {
    background-color: $WhiteColor;
  }
}

.noResultContainer {
  padding: 12px;
  text-align: center;
}

.searchForm {
  width: 100%;
}

.searchResult {
  width: 100%;
}
