@import "../../../../../styles/guide";
@import "../../../../../styles/variables";
@import "../../../../../styles/servelegal-font-variables";

.birthDateInput {
  margin: 0em 0em 1em;
  width: 100% !important;
}

.titleQuestion {
  color: $AuxiliarColor;
  font-size: 14px;
  line-height: 16px;
  margin: 8px 0px 16px 0px;
  text-align: center;
  width: 100%;
}

.note {
  color: $AuxiliarColor;
  font-size: 12px;
  font-style: italic;
  margin: 8px 0px 16px 0px;
  text-align: center;
  width: 100%;
}