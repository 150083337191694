@import '../../../../../styles/guide';
@import '../../../../../styles/servelegal-font-variables';

.button {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.field {
  margin-bottom: 10px;
  width: 100%;
  div {
    width: 100%;
  }
}

.row {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.search {
  width: 85%;
  div {
    line-height: 18px;
    text-overflow: ellipsis;
    width: 95%;
  }
}

.remove {
  @extend %icon;
  background-color: $FogColor;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 4px;
  padding: 8px 12px;

  &::before {
    color: $SecondaryColor;
    content: $icon-trash;
  }
}