@import "../../../../styles/variables";
@import "../../../../styles/servelegal-font-variables";
@import "../../../../styles/guide";

.tab {
  background-color: $WhiteColor !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  display: flex;
  justify-content: center;
  margin-bottom: 0px !important;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 10px 0px 0px 0px;
}