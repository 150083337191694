@import '../../../../styles/variables';

.downloadContainer{
  margin-left: 19%;
}

.download {
  color: $HelpColor;
  cursor: pointer;
  text-decoration: underline;
}

.modalWrapper {
  width: 70%;
}
