@import '../../../../styles/guide';
@import '../../../../styles/servelegal-font-variables';

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;

  p {
    margin-bottom: 56px !important;
  }
}

.loadingTitle{
  padding-top: 50px;
}

.loader{
  margin-bottom: 56px!important;
  position: relative;
}