@import "../../../../../styles/variables";
@import "../../../../../styles/servelegal-font-variables";
@import "../../../../../styles/guide";

.input {
  width: 100%;
}

.textArea {
  height: 180px;
  width: 100%;
  textarea {
    border-color: $GrayColor;
    border-radius: 0.2rem;
    height: 100%;
    padding: 16px 16px;
    width: 100%;
    &::placeholder {
      color: $SecondaryColor;
    }
  }
}

.question {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  input {
    width: 100% !important;
  }
}

.skipRegExp {
  align-self: flex-start;
  padding: 10px 0;
}