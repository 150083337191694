@import "../../../styles/variables";

.buttonsContainer {
  margin-top: 8px;
}

.emptyContainer {
  align-items: center !important;
  display: flex !important;
  flex-direction: column;
  height: 100%;
  justify-content: center !important;
}

.searchContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sortableTreeContainer {
  height: 350px;
}

.selectedNode {
  color: $PrimaryColor;
  font-size: 16px !important;
}