@import '../../../../../styles/variables';

.container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  width: 100% !important;
}

.row {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }
}

.image {
  background-color: $SecondaryColor !important;
  display: block;
  height: auto;
}

.image:hover {
  cursor: zoom-in;
}