@import '../../../../styles/guide';
@import '../../../../styles/servelegal-font-variables';

.reviewButton {
  display: flex;
  justify-content: flex-end;
}

.gridWrapper {
  gap: 14px !important;
  padding: 0 !important;
}

.columnWrapper {
  padding: 0 !important;
}

.column {
  div input{
    width: 200px !important;
  }
}

.sendIcon {
  @extend %icon-before;
  font-size: 12px;
  margin-left: 16px;

  &::before {
    content: $icon-send;
  }
}
.labelStyle {
  font-size: 0.99rem !important;
}

.card {
  padding: 10px 10px 15px 10px !important;
  width: 100% !important;
}
