@import "../../../../styles/guide";
@import "../../../../styles/variables";
@import "../../../../styles/servelegal-font-variables";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
  width: 100%;
}

.subtitle {
  color: $AuxiliarColor;
  font-size: 17px;
  margin-top: 12px;
  text-align: center;
}

.iconError {
  @extend %icon-after;
  align-items: center;
  display: flex;
  height: 120px;
  justify-content: center;
  margin-top: 40px;
  width: 100%;

  &::after {
    color: $PrimaryColor;
    content: $icon-close_thick;
    font-size: 100px;
  }
}

.iconArrow {
  margin-left: 32px !important;
}