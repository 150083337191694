@import '../../../../../styles/variables';
@import '../../../../../styles/guide';
@import '../../../../../styles/servelegal-font-variables';

%action-icon {
  @extend %icon;
  cursor: pointer;
}

.remove {
  @extend %action-icon;

  &::before {
    content: $icon-trash;
  }
}
