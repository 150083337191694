@import '../../../../../styles/variables';

.list {
  width: 70%;
}

.selected {
  color: $PrimaryColor;
}

.actionItem {
  cursor: pointer;
}