@import '../../../../styles/guide';
@import '../../../../styles/servelegal-font-variables';

.container {
  display: flex;
  flex-direction: column;
  // TODO: height was commented out because it was causing the pagination not to be shown. we should investigate any unexpected output of commenting this
  // height: 100%;
  justify-content: space-between;
}

.accordion {
  height: 100%;
  &>div:nth-child(odd) {
    margin-top: 25px;
  }
}

.accordionTitle {
  align-items: center;
  background-color: $WhiteColor;
  display: flex;
  font-weight: bold;
  justify-content: center;
}

.title{
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  width: 100%;
  i {
    margin-right: 16px !important;
  }
}

.accordionContent {
  background-color: $FogColor;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 15px 0px !important;
}

.auditIcon {
  @extend %icon-before;
  color: $PrimaryColor;
  font-size: 12px;

  &::before {
    content: $icon-visits_current;
  }
}

.checkboxContainer {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  padding: 8px;
}
