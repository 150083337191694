@import '../../../../../styles/variables';

.root {
  color: $HelpColor;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.example {
  color: $HelpColor;
  cursor: pointer;
  text-decoration: underline;
}

.form {
  margin-top: 40px;
}
