
.root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
}

.mainContent {
  display: flex;
  flex: 1;
  width: 100%;
}

.cardContainer {
  flex: 1;
  max-width: 100%;
  padding: 8px;
}

.mobileCardContent {
  overflow: hidden;
  padding: 20px 10px !important;
}

.card {
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

.sidebarContainer {
  overflow-y: auto;
  padding: 8px;
  width: 440px;
}

.sidebarFixContainer {
  height: 95vh;
  overflow-y: scroll;
  position: fixed;
  width: inherit;
}

.filterViewFalse {
  display: none;
}

.footContainer {
  padding: 8px;
  width: 100%;
}
