@import '../../../styles/guide';
@import '../../../styles/variables';
@import '../../../styles/servelegal-font-variables';

.root {
  background-color: $GrayColor !important;
  border-radius: 8px !important;
}

.headerContainer {
  background-color: $WhiteColor !important;
  width: 100%;
}

.headerRoot {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  user-select: none;
  width: 100%;
}

%collapse-toggle {
  @extend %icon-after;
  background-color: transparent !important;
  color: $SecondaryColor !important;
  margin-left: auto !important;
  padding: 0 !important;
}

.collapsed {
  @extend %collapse-toggle;

  &::after {
    content: $icon-arrowLeft_mid;
  }
}

.notCollapsed {
  @extend %collapse-toggle;

  &::after {
    content: $icon-arrowDown_mid;
  }
}

.contentContainer {
  background-color: $GrayColor;
  border-radius: 8px !important;
  padding: 24px !important;
}

.collapsedContent {
  border-radius: 8px !important;
  display: none;
}