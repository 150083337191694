@import '../../../../../styles/variables';

.container {
  width: 100% !important;
}

.headerContainer {
  background-color: $SecondaryColor !important;
}

.headerTitle {
  color: $WhiteColor !important;
}

.row {
  display: flex;
  justify-content: space-between
}

.image {
  background-color: $SecondaryColor !important;
  height: auto;
  max-height: 30px;
  width: 30px;
}
