@import '../../../styles/guide';
@import '../../../styles/servelegal-font-variables';

.root {
  display: flex;
  flex-direction: column;
  height: 95vh;
  width: 100%;
}

.card {
  height: 100%;
}

.report {
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  width: 100%;
}

%action {
  color: $SecondaryColor;
  font-size: 13px;
  padding: 0 12px;
}

.pdf {
  @extend %action;
  cursor: pointer;
}

.icon {
  color: $PrimaryColor;
}
