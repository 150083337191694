@import '../../../styles/guide';
@import '../../../styles/variables';

.root {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  min-height: 42px;
}

.title {
  text-transform: capitalize;
}

.subtitle {
  @extend %h6;
  color: $HelpColor;
  padding-left: 34px;
}

.content {
  align-items: center;
  display: flex;
  margin-left: auto;
}

.status{
  margin-left: 10px;
  text-transform: capitalize;
}