@import '../../../styles/guide';

.image {
  height: auto;
  max-height: 30px;
  width: 30px;
}

.link {
  @extend %table-link;
}

.bold {
  font-weight: bold;
}

.primary {
  color: $PrimaryColor;
}

.secondary {
  color: $SecondaryColor;
}

.warning {
  color: #e0a403;
}
