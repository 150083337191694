@import "../../../styles/variables";
@import "../../../styles/guide";

.tabIcon {
  font-size: 1.5rem;
  padding-bottom: 3px;
  z-index: 1;
}

.tabLabel {
  font-weight: 500;
  padding: 0 10px;
  text-align: center;
  z-index: 1;
}

.rootMobile {
  background-color: $WhiteColor;
  bottom: 0px;
  height: 56px;
  position: fixed;
  width: 100vw;
  z-index: 2;
}

.navbarMobile {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 4px 0px;
}

.tabMobile {
  align-items: center;
  color: $SecondaryColor;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 56px;
  justify-content: center;
  position: relative;
}

.activeTabMobile {
  color: $PrimaryColor;
}

.dropdownBg {
  background: linear-gradient(0deg, #5F8298 0%, #EE485F 100%) !important;
  height: calc(100vh - 56px);
  padding-left: 24px !important;
  position: fixed !important;
  top: 0 !important;
  visibility: hidden;
  width: 100vw !important;
}

.dropdownContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.dropdownHeader {
  color: $WhiteColor;
  margin-bottom: 40px;
}

.tabDropdown {
  align-items: center;
  color: $WhiteColor;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 56px;
  position: relative;
}

.dividerMobile {
  border-color: $AuxiliarColor !important;
}
