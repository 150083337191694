@import '../../../../styles/variables';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
}

.card {
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

.cardContent {
  background-color: $FogColor !important;
  padding: 0px !important;
}

.questionsColumn {
  padding-right: 0 !important;
  padding-top: 0 !important;
}

.detailsColumn {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.header {
  align-items: center;
  background-color: $FogColor;
  color: #5F8298;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  height: 50px;
  justify-content: center;
}

.scrollable {
  background-color: #f7f9fb;
  height: 1024px !important;
  overflow: auto !important;
  padding: 16px 16px;

}

.contentData {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  :first-child {
    font-weight: bold;
  }
}

.grid {
  margin-top: 0px !important;
}