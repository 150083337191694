@import '../../../../styles/guide';
@import '../../../../styles/servelegal-font-variables';
@import '../../../../styles/variables';

%action {
  color: $SecondaryColor;
  font-size: 13px;
  padding: 0 12px;
}

.label {
  @extend %input-label-right-align;
}

.siteUploadHelp {
  color: $HelpColor;
  line-height: 22px;

  > ul {
    margin-block-end: 0;
    margin-block-start: 0;
    padding-inline-start: 16px;
  }
}

.example {
  cursor: pointer;
  text-decoration: underline;
}

.actionsRow {
  background-color: $FogColor;
  display: flex;
  justify-content: flex-end;
  margin: -32px -32px 32px -32px;
  padding: 10px 10px;
}