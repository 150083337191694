@import "../../../../styles/guide";
@import "../../../../styles/servelegal-font-variables";

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cardHeader {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.title {
  margin-right: auto;
  text-transform: capitalize;
}

.save {
  @extend %icon-before;

  &::before {
    @extend %primary;
    color: $WhiteColor;
    content: $icon-save;
  }
}

.subFields {
  display: flex;
  flex-direction: column;
  width: 100%;

  >*:not(:last-child) {
    padding-bottom: 16px;
  }
}

.termsBox {
  margin-top: 16px;

  textarea {
    height: 262px;
  }

  .save {
    @extend %icon-before;

    &::before {
      @extend %primary;
      color: $WhiteColor;
      content: $icon-save;
    }
  }
}

.linkButton {
  cursor: pointer;
  text-decoration: underline;
}
