
@import '../../../../styles/variables';

.modalCard {
  align-items: center;
  border-radius: 8px !important;
  box-shadow: 0 10px 30px 0 rgba(43, 49, 64, 0.06) !important;
  display: flex;
  flex-direction: column !important;
  padding: 32px 40px !important;
  width: 500px  !important;
}

.form {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 55px;
  width: 100%;
}

.description {
  text-align: center;
}
